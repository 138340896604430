<template>
  <div class="akz-header">
    <div class="akz-header--logo">
      <img src="@/assets/logo.png" style="width: 38px; height: 38px;" />
      <div class="akz-header--logo-text">安考站</div>
    </div>
    <div class="akz-header--menu">
      <a class="akz-header--menu-item" href="#home">首页</a>
      <a class="akz-header--menu-item" href="#service">服务介绍</a>
      <a class="akz-header--menu-item" href="#company">公司简介</a>
      <a class="akz-header--menu-item" href="#contact">联系我们</a>
    </div>
  </div>
  <div id="home" class="akz-body-pan">
    <div class="akz-show">
      <div class="akz-show-pan--row-1">安考站</div>
      <div class="akz-show-pan--row-2">三类人员(ABC证)、八大员、技工证、特种作业操作证、煤炭矿山、职业技能鉴定的考试\培训\教学专家</div>
    </div>
  </div>
  <div class="akz-company-pan">
    <div id="service" class="akz-company">
      <div class="akz-company-words">
        <div class="akz-company--describe-title">专注行业，服务专业</div>
        <div class="akz-company--describe-items">
          <div class="akz-company--describe-item">
            <div class="akz-company--describe-item-title">三类人员(ABC证)</div>
            <div class="akz-company--describe-item-context">提供全国三类人员考试题库模拟测试，重庆市三类人员培训官方报名服务</div>
          </div>
          <div class="akz-company--describe-item">
            <div class="akz-company--describe-item-title">八大员</div>
            <div class="akz-company--describe-item-context">安全员、安装施工员、安装质量员、标准员、材料员、测量员、机械员、劳务员、实验员、土建施工员、土建质量员、预算员、资料员等八大员科目的培训和考试模拟练习</div>
          </div>
          <div class="akz-company--describe-item">
            <div class="akz-company--describe-item-title">建筑类的技工</div>
            <div class="akz-company--describe-item-context">提供木工,砌筑工,模板工,抹灰工,脚手架工,电工,焊工,架线工,钢筋工培训服务</div>
          </div>
        </div>
      </div>
      <div class="akz-company-pic">

      </div>
    </div>
  </div>
  <div class="akz-products-pan">
    <div class="akz-products">
      <div id="company" class="akz-product-title">
        公司介绍
      </div>
      <div class="akz-product-items">
        <div class="akz-product-item">
          <img class="akz-product--img" src="@/assets/capture-1.jpg" fit="fill" />
          <div class="akz-product--content">
            <div class="akz-product--content-title">建设中..</div>
            <div class="akz-product--content-content">Make. Lights us. Is life all make midst a moveth forth under may Cattle moved without seas first air beast place after.</div>
          </div>
        </div>
        <div class="akz-product-item">
          <img class="akz-product--img" src="@/assets/capture-1.jpg" fit="fill" />
          <div class="akz-product--content">
            <div class="akz-product--content-title">建设中..</div>
            <div class="akz-product--content-content">Make. Lights us. Is life all make midst a moveth forth under may Cattle moved without seas first air beast place after.</div>
          </div>
        </div>
        <div class="akz-product-item">
          <img class="akz-product--img" src="@/assets/capture-1.jpg" fit="fill" />
          <div class="akz-product--content">
            <div class="akz-product--content-title">建设中..</div>
            <div class="akz-product--content-content">Make. Lights us. Is life all make midst a moveth forth under may Cattle moved without seas first air beast place after.</div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="akz-contact-us-pan">
    <div class="akz-contact-us">
      <div id="contact" class="akz-contact-us--title">联系我们</div>
      <div class="akz-contact-us--phone">联系人：周先生<br />电话：023-86589557</div>
    </div>
  </div>
  <div class="link-bottom">
    <a href="https://beian.miit.gov.cn" style="color:#38a75d" target="_blank">渝ICP备18006070号-1</a>
  </div>
</template>
<script>

export default {
  name: 'App',
  components: {},
  methods: {}
}
</script>

<style lang="scss">
body{
  margin: 0;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
.akz-header{
  display: flex;
  background-color: #fff;
  color: #31AC71;
  justify-content: space-between;
  width: 1080px;
  margin: 0 auto;
  .akz-header--menu{
    display: flex;
    margin-top: 20px;
    font-size: 18px;
    .akz-header--menu-item,.akz-header--menu-item:visited{
      padding:10px 15px;
      line-height: 50px;
      font-weight: 400;
      text-decoration: none;
      color: #31AC71;
    }
  }
  .akz-header--logo{
    display: flex;
    align-items: center;
    .akz-header--logo-text{
      font-size: 28px;
      color: #000;
      margin-left: 8px;
      font-weight: 300;
    }
  }
}
.akz-body-pan{
  background-image: linear-gradient(#31AC71, #1f8153 90%);
  height: 400px;
  .akz-show{
    margin: 0 auto;
    font-size: 50px;
    font-weight: 900;
    text-align: left;
    width: 1080px;
    padding-top:100px;
    .akz-show-pan--row-1{
      color: yellow;
    }
    .akz-show-pan--row-2{
      color: #fff;
      font-size: 30px;
      width: 600px;
    }
  }
}
.akz-company-pan{
  background-color: #fff2de;
  background-size: 10px 10px;
  display: flex;
  background-image: -webkit-gradient(linear, 0 0, 100% 100%, color-stop(.25, #ffe2ab), color-stop(.25, transparent), color-stop(.5, transparent), color-stop(.5, #ffe2ab), color-stop(.75, #ffe2ab), color-stop(.75, transparent), to(transparent));
  background-image: -moz-linear-gradient(-45deg, #ffe2ab 25%, transparent 25%, transparent 50%, #ffe2ab 50%, #ffe2ab 75%, transparent 75%, transparent);
  background-image: -o-linear-gradient(-45deg, #ffe2ab 25%, transparent 25%, transparent 50%, #ffe2ab 50%, #ffe2ab 75%, transparent 75%, transparent);
  background-image: linear-gradient(-45deg, #ffe2ab 25%, transparent 25%, transparent 50%, #ffe2ab 50%, #ffe2ab 75%, transparent 75%, transparent);
  .akz-company{
    margin: 0 auto;
    margin-top: -60px !important;
    width: 1080px;
    background-color: #ffffff;
    box-shadow: 0 0 40px rgba(0, 0, 0, 0.1);
    display: flex;
    justify-content: space-between;
    border-radius: 40px 40px 0 0;
    .akz-company-words{
      text-align: left;
      .akz-company--describe-title{
        padding: 40px 0 40px 40px;
        font-size: 40px;
        font-weight: 700;
        width: 600px;
        word-break: break-all;
        color: #31AC71;
      }
      .akz-company--describe-items{
        display: flex;
        justify-content: space-between;
        width: 640px;
        .akz-company--describe-item{
          padding-left:40px;
          flex: 1;
          .akz-company--describe-item-title{
            font-weight: 600;
            font-size: 20px;
            color: #000;
          }
          .akz-company--describe-item-context{
            font-size: 16px;
            margin-top: 20px;
          }
        }
      }
    }
    .akz-company-pic{
      background-image: url("@/assets/intro-thumb.png");
      width: 372px;
      height: 786px;
      margin-top: -300px;
    }
  }
}
.akz-products-pan{
  background-color: #fff2de;
  padding-bottom: 120px;
  .akz-products {
    width: 1080px;
    margin:0 auto;
    .akz-product-items{
      display: flex;
      justify-content: space-between;
      .akz-product-item{
        width: 100%;
        height: 400px;
        margin: 0 15px;
        .akz-product--content{
          background-color: #fff;
          text-align: left;
          padding:40px;
          margin-top: -10px;
          .akz-product--content-title{
            word-break: break-all;
            font-weight: 600;
            font-size: 22px;
            color: #000;
          }
          .akz-product--content-content{
            font-size: 16px;
            margin-top: 20px;
          }
        }
      }
      .akz-product--img{
        width: 100%;
      }
    }
    .akz-product-title{
      font-size: 40px;
      font-weight: 900;
      text-align: left;
      padding-top: 50px;
      padding-bottom: 50px;
      color: #1c7a4d;
    }
  }
}
.akz-contact-us-pan{
  .akz-contact-us{
    width: 1080px;
    margin:0 auto;
    text-align: left;
    .akz-contact-us--title{
      font-size: 40px;
      font-weight: 900;
      padding-top: 50px;
      padding-bottom: 20px;
      color: #1c7a4d;
    }
    .akz-contact-us--phone{
      font-size: 20px;
      font-weight: 700;
    }
  }
}
.link-bottom{
 padding:100px 0 60px 0;
}
</style>
